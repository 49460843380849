.report-class {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  /* margin: 1% auto; */
}

.report-class-full {
  height: 105vh;
  width: 100%;
  overflow: hidden;
  /* margin: 1% auto; */
}

iframe {
  border: none;
}
