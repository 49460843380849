.popup-content {
  margin: auto;
  width: 50%;
  padding: 5px;

  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 4px;
  color: var(--text);
}
.popup-arrow {
  color: var(--background);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}
