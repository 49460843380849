.react-datepicker {
  font-size: 2rem;
}
.react-datepicker__header {
  padding-top: 1.8rem;
}
.react-datepicker__month {
  margin: 1rem 1rem;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 3rem;
  line-height: 3rem;
  margin: 0.7rem;
}
.react-datepicker__current-month {
  font-size: 2rem;
}
.react-datepicker__navigation {
  top: 2rem;
  line-height: 2rem;
  /* border: 3px solid transparent; */
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 2rem;
  font-size: 2rem;
  font-weight: bold;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 2rem;
  font-size: 2rem;
  font-weight: bold;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-right: 1px solid #ccc;
  border-bottom-right-radius: 0.3rrem;
}

.react-datepicker-time__header {
  font-size: 2rem;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -87px;
  top: -1px;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  /* border: 1px solid #aeaeae; */
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}
